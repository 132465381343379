/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
    .info-box {
        color: #444444;
        text-align: center;
        box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
        padding: 30px 0 32px 0;
        border-radius: 4px;
        i {
            font-size: 32px;
            color: var(--oksijen-turuncu);
            border-radius: 50%;
            padding: 8px;
            border: 2px dotted #f8d4d5;
        }
        h3 {
            font-size: 20px;
            color: #777777;
            font-weight: 700;
            margin: 10px 0;
        }
        p {
            padding: 0;
            line-height: 24px;
            font-size: 14px;
            margin-bottom: 0;
        }
    }
    .php-email-form {
        box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
        padding: 30px;
        border-radius: 4px;
        .error-message {
            display: none;
            color: rgba(255, 255, 255, 1);
            background: #ed3c0d;
            text-align: left;
            padding: 15px;
            font-weight: 600;
            br {
                margin-top: 25px;
            }
        }
        .sent-message {
            display: none;
            color: rgba(255, 255, 255, 1);
            background: #18d26e;
            text-align: center;
            padding: 15px;
            font-weight: 600;
        }
        .loading {
            display: none;
            background: rgba(255, 255, 255, 1);
            text-align: center;
            padding: 15px;
            &:before {
                content: "";
                display: inline-block;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                margin: 0 10px -6px 0;
                border: 3px solid #18d26e;
                border-top-color: #eee;
                -webkit-animation: animate-loading 1s linear infinite;
                animation: animate-loading 1s linear infinite;
                @-webkit-keyframes animate-loading {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
                @keyframes animate-loading {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }
        .form-group {
            margin-bottom: 25px;
        }
        input {
            padding: 10px 15px;
        }
        textarea {
            padding: 12px 15px;
        }
        input, textarea {
            box-shadow: none;
            font-size: 14px;
            border-radius: 4px;
            &:focus {
                border-color: rgba(17, 17, 17, 1);
            }
        }
        button[type=submit] {
            background: var(--oksijen-turuncu);
            border: 0;
            padding: 10px 32px;
            color: rgba(255, 255, 255, 1);
            transition: 0.4s;
            border-radius: 4px;
            &:hover {
                background: rgba(148, 90, 42, 1);
            }
        }
    }
}
